import { Box, Card, Typography } from '@mui/material';

import { useLogo } from '../../../hooks/useLogo';
import { SignLayoutType } from '../../../types/auth';

export function SignLayout({ children }: SignLayoutType): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        bgcolor: 'background.default',
      }}
    >
      <Card
        variant="outlined"
        sx={{ padding: [3, 4], marginX: 3, textAlign: 'center' }}
      >
        <Card
          variant="outlined"
          component="img"
          src={useLogo()}
          alt="Food Checker"
          sx={{
            width: '100%',
            maxHeight: '100px',
            maxWidth: '200px',
            marginTop: '1rem',
            marginBottom: '3rem',
            padding: [1, 2],
            bgcolor: 'common.white',
          }}
        />
        {children}
      </Card>
      <Typography
        color="secondary"
        variant="caption"
        sx={{ position: 'fixed', bottom: 10, fontWeight: 300, opacity: 0.5 }}
      >
        © Food Checker.
      </Typography>
    </Box>
  );
}
