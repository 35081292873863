import { AppBar, Box, Toolbar } from '@mui/material';

import { Logo } from './Logo';
import { MenuIcon } from './MenuIcon';
import { Notification } from './Notification';
import { Profile } from './Profile';

export function Header(): JSX.Element {
  return (
    <Box
      sx={{
        display: 'flex',
        flexGrow: 1,
        width: '100%',
        maxWidth: 1480,
        marginX: 'auto',
        paddingX: [1, 3],
        paddingTop: [1, 2],
        alignItems: 'center',
      }}
    >
      <AppBar position="static" sx={{ backgroundColor: 'transparent' }}>
        <Toolbar sx={{ paddingX: [0] }}>
          <MenuIcon />
          <Box sx={{ flexGrow: 1 }}>
            <Logo />
          </Box>
          <Box sx={{ display: 'flex', gap: 1.25, alignItems: 'center' }}>
            <Notification />
            <Profile />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
