import { FC, useContext } from 'react';

import { AuthContext } from '../contexts/AuthContext';
import { AdminRoutes } from './AdminRoutes';
import { SignRoutes } from './SignRoutes';

export const Routes: FC = () => {
  const { isAuthenticated } = useContext(AuthContext);

  return isAuthenticated ? <AdminRoutes /> : <SignRoutes />;
};
