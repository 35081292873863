import { Box, Link } from '@mui/material';

// import LogoImg from '../../../assets/logo-quality.svg';
import { useLogo } from '../../../hooks/useLogo';

export function Logo(): JSX.Element {
  return (
    <Link href="/">
      <Box
        component="img"
        src={useLogo()}
        alt="Food Checker"
        sx={{
          height: '50px',
          maxWidth: '100%',
          marginTop: 0,
          marginBottom: ['-8px', '-10px'],
        }}
      />
    </Link>
  );
}
