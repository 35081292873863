import { parseCookies } from 'nookies';

import LogoImg from '../assets/logo-quality.svg';

export function useLogo(): string {
  const Logo = localStorage.getItem('fcLogo');
  // const { fcLogo: Logo } = parseCookies();
  if (Logo) {
    return Logo;
  }
  return LogoImg;
}
