import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DashboardIcon from '@mui/icons-material/Dashboard';
import DescriptionIcon from '@mui/icons-material/Description';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import { isDesktop } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';

import {
  drawerWidthValue,
  SidebarDrawerContext,
} from '../../contexts/SidebarDrawerContext';

export function Sidebar(): JSX.Element {
  const { pathname } = useLocation();

  const container =
    window !== undefined ? () => window.document.body : undefined;

  const drawer = (
    <div>
      <Toolbar>
        <Typography
          sx={{ display: ['block', 'none'], color: 'text.secondary' }}
        >
          MENU
        </Typography>
      </Toolbar>
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          to="/"
          sx={{ color: pathname === '/' ? 'success.main' : 'inherit' }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <DashboardIcon sx={{ color: 'primary.light' }} />
          </ListItemIcon>
          <ListItemText primary="Início" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/action-plans"
          sx={{
            color: pathname === '/action-plans' ? 'success.main' : 'inherit',
          }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <CheckCircleIcon sx={{ color: 'primary.light' }} />
          </ListItemIcon>
          <ListItemText primary="Planos de Ação" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/documents"
          sx={{ color: pathname === '/documents' ? 'success.main' : 'inherit' }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <SubtitlesIcon sx={{ color: 'primary.light' }} />
          </ListItemIcon>
          <ListItemText primary="Documentos" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/reports"
          sx={{ color: pathname === '/reports' ? 'success.main' : 'inherit' }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <DescriptionIcon sx={{ color: 'primary.light' }} />
          </ListItemIcon>
          <ListItemText primary="Relatórios" />
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/contacts"
          sx={{ color: pathname === '/contacts' ? 'success.main' : 'inherit' }}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>
            <PeopleAltIcon sx={{ color: 'primary.light' }} />
          </ListItemIcon>
          <ListItemText primary="Equipe" />
        </ListItem>
      </List>
    </div>
  );

  return (
    <SidebarDrawerContext.Consumer>
      {({ drawerOpen, handleDrawerToggle }) => (
        <>
          <Drawer
            container={container}
            variant="temporary"
            open={drawerOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              display: isDesktop ? 'none' : 'block',
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidthValue,
              },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="persistent"
            sx={{
              display: isDesktop ? 'block' : 'none',
              '& .MuiDrawer-paper': {
                boxSizing: 'border-box',
                width: drawerWidthValue,
              },
            }}
            open={drawerOpen}
          >
            {drawer}
          </Drawer>
        </>
      )}
    </SidebarDrawerContext.Consumer>
  );
}
