import axios, { AxiosInstance } from 'axios';
import { destroyCookie, parseCookies } from 'nookies';

function setAuthToken(config: any) {
  const userCookies = localStorage.getItem('fcQualityUser');
  // const { fcQualityUser: userCookies } = parseCookies();
  config.headers.Authorization = '';
  if (userCookies) {
    config.headers.Authorization = `Bearer ${JSON.parse(userCookies).token}`;
  }
}

export function getAPIClient(): AxiosInstance {
  // const history = useHistory();
  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });

  api.interceptors.request.use(
    (config) => {
      setAuthToken(config);

      if (config.url === 'access_token') {
        // config.headers.Authorization = '';
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response?.status === 401) {
        localStorage.removeItem('fcQualityUser');
        // destroyCookie(undefined, 'fcQualityUser');
        // history.push('/login');
        window.location.href = '/login';
      }

      return Promise.reject(error.response?.data || error.request || error);
    }
  );

  setAuthToken(api.defaults);

  return api;
}
