import { Box } from '@mui/material';
import { isDesktop } from 'react-device-detect';

import { SidebarDrawerContext } from '../../../contexts/SidebarDrawerContext';
import { AdminLayoutType } from '../../../types/layout';
import { Header } from '../../Header';
import { Sidebar } from '../../Sidebar';

export function AdminLayout({ children }: AdminLayoutType): JSX.Element {
  return (
    <SidebarDrawerContext.Consumer>
      {({ drawerWidth }) => (
        <Box
          sx={{
            flexDirection: 'column',
            height: '100vh',
            marginLeft: `${isDesktop ? drawerWidth : 0}px`,
            transition: 'all 225ms cubic-bezier(0, 0, 0.2, 1) 0ms',
          }}
        >
          <Header />
          <Box
            sx={{
              display: 'flex',
              width: '100%',
              marginY: [1, 3],
              paddingX: [0, 3],
              marginX: 'auto',
              maxWidth: 1024, // 1480
            }}
          >
            <Sidebar />
            <Box
              bgcolor="background.paper"
              sx={{
                flex: 1,
                borderRadius: [0, 1],
                padding: [2, 4],
                marginBottom: [0, 2],
                borderTopWidth: 1,
                borderLeftWidth: [0, 1],
                borderRightWidth: [0, 1],
                borderBottomWidth: [0, 1],
                borderStyle: 'solid',
                borderColor: 'divider',
                minHeight: ['calc(100vh - 56px - 1rem)', 'auto'],
              }}
            >
              {children}
            </Box>
          </Box>
        </Box>
      )}
    </SidebarDrawerContext.Consumer>
  );
}
