import { Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';

import { TitleType } from '../../types/title';

export function Title({ title }: TitleType): JSX.Element {
  return (
    <>
      <Helmet>
        <title>{title} | Segurança dos Alimentos</title>
      </Helmet>
      <Typography
        color="primary"
        variant="h1"
        sx={{ marginBottom: [2, 5], fontSize: ['1.5rem', '1.875rem'] }}
      >
        {title}
      </Typography>
    </>
  );
}
