import { IActionPlansSituation } from '../../types/actionPlans';

const situations: IActionPlansSituation[] = [
  { id: 0, name: 'Pendente', color: '#f67600', icon: 'hourglass_empty' },
  { id: 1, name: 'Em Andamento', color: '#00bcd4', icon: 'hourglass_full' },
  { id: 2, name: 'Concluído', color: '#00A76D', icon: 'check' },
  { id: 3, name: 'Atrasado', color: '#f44336', icon: 'hourglass_top' },
  { id: 4, name: 'Adiantado', color: '#00bcd4', icon: 'hourglass_full' },
  { id: 5, name: 'Vencido', color: '#b50001', icon: 'hourglass_disabled' },
];

export function getAllActionPlansStatus(): {
  data: IActionPlansSituation[];
} {
  return { data: situations };
}

export function listsActionPlansStatus(): {
  data: IActionPlansSituation[];
} {
  return getAllActionPlansStatus();
}

export function getByIdActionPlansStatus(
  id: number | undefined
): IActionPlansSituation | undefined {
  return situations.find((e) => e.id === id);
}
