import {
  Box,
  Card,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { Content } from '../../components/Content';
import { AuthContext } from '../../contexts/AuthContext';
import { api } from '../../services/Api';
import { ClientType } from '../../types/client';

export function Dashboard(): JSX.Element {
  const { user } = useContext(AuthContext);
  const [client, setClient] = useState<ClientType | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<{ data: ClientType }>('client?with=category')
      .then((response) => {
        setClient(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  return (
    <Content title="Início" loading={loading} notFound={!client}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Card
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: ['column', 'row'],
            alignContent: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
            padding: 3,
            marginBottom: 5,
            width: 'max-content',
            marginX: 'auto',
            bgcolor: 'common.white',
          }}
        >
          {(client?.file_name || client?.category?.icon) && (
            <Box
              component="img"
              src={client?.file_name || client?.category?.icon}
              sx={{ height: ['45px', '60px'], maxWidth: '100%' }}
            />
          )}
          {client?.file_name && user?.account?.file_name && (
            <>
              <Typography
                variant="h1"
                component="h2"
                color="secondary"
                sx={{ margin: 2 }}
              >
                &
              </Typography>
              <Box
                component="img"
                src={user?.account?.file_name}
                sx={{ height: ['45px', '60px'], maxWidth: '100%' }}
              />
            </>
          )}
        </Card>
        <Typography variant="h1" component="h2" gutterBottom>
          {client?.name}
        </Typography>
        <Typography variant="h4" component="h4">
          {client?.category?.name}
        </Typography>
        <TableContainer
          component={Paper}
          sx={{ width: 'auto', marginX: 'auto' }}
        >
          <Table sx={{ width: 'auto', marginTop: 3 }}>
            <TableBody>
              {client?.phone && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, borderColor: 'divider' }}
                  >
                    TELEFONE
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'divider' }}>
                    {client?.phone}
                  </TableCell>
                </TableRow>
              )}
              {client?.phone_2 && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, borderColor: 'divider' }}
                  >
                    TELEFONE
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'divider' }}>
                    {client?.phone_2}
                  </TableCell>
                </TableRow>
              )}
              {client?.email && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, borderColor: 'divider' }}
                  >
                    E-MAIL
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'divider' }}>
                    {client?.email}
                  </TableCell>
                </TableRow>
              )}
              {client?.site && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, borderColor: 'divider' }}
                  >
                    SITE
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'divider' }}>
                    {client?.site}
                  </TableCell>
                </TableRow>
              )}
              {client?.company_name && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, borderColor: 'divider' }}
                  >
                    RAZÃO SOCIAL
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'divider' }}>
                    {client?.company_name}
                  </TableCell>
                </TableRow>
              )}
              {client?.cnpj && (
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{ fontWeight: 500, borderColor: 'divider' }}
                  >
                    CNPJ
                  </TableCell>
                  <TableCell align="right" sx={{ borderColor: 'divider' }}>
                    {client?.cnpj}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Content>
  );
}
