import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { AdminLayout } from '../../components/Layouts/AdminLayout';
import { SidebarDrawerProvider } from '../../contexts/SidebarDrawerContext';
import { ActionPlans } from '../../pages/ActionPlans';
import { ActionPlan } from '../../pages/ActionPlans/view';
import { Contacts } from '../../pages/Contacts';
import { Dashboard } from '../../pages/Dashboard';
import { Documents } from '../../pages/Documents';
import { NotFound } from '../../pages/NotFound';
import { Profile } from '../../pages/Profile';
import { Reports } from '../../pages/Reports';
import { Elements } from '../../pages/Tests/elements';
import { Hello } from '../../pages/Tests/hello';
import { Scroll } from '../../pages/Tests/scroll';

export const AdminRoutes: FC = () => {
  return (
    <SidebarDrawerProvider>
      <AdminLayout>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/action-plans" component={ActionPlans} />
          <Route exact path="/action-plans/:id" component={ActionPlan} />
          <Route exact path="/documents" component={Documents} />
          <Route exact path="/reports" component={Reports} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/profile" component={Profile} />
          <Route exact path="/tests/hello" component={Hello} />
          <Route exact path="/tests/elements" component={Elements} />
          <Route exact path="/tests/scroll" component={Scroll} />
          <Route component={NotFound} />
        </Switch>
      </AdminLayout>
    </SidebarDrawerProvider>
  );
};
