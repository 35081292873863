import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

import { SignLayout } from '../../components/Layouts/SignLayout';
import { Login } from '../../pages/Login';
import { LoginAdmin } from '../../pages/LoginAdmin';

export const SignRoutes: FC = () => {
  return (
    <SignLayout>
      <Switch>
        <Route path="/login/admin" component={LoginAdmin} />
        <Route path="/login" component={Login} />
        <Route component={Login} />
      </Switch>
    </SignLayout>
  );
};
