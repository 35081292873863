import { Card, CardContent, Grid, Icon } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ActionPlanFile } from '../../components/ActionPlan/ActionPlanFile';
import { Content } from '../../components/Content';
import { ListButton } from '../../components/Content/ListButton';
import { TextBody } from '../../components/TextBody';
import { TextSpan } from '../../components/TextSpan';
import { TextSubtitle } from '../../components/TextSubtitle';
import { getByIdActionPlansStatus } from '../../services/ActionPlansStatus';
import { api } from '../../services/Api';
import { ActionPlanType } from '../../types/actionPlans';

export function ActionPlan(): JSX.Element {
  const { id } = useParams<{ id: string }>();

  const [actionPlan, setActionPlan] = useState<ActionPlanType>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      api
        .get<{ data: ActionPlanType }>(
          `action_plans/${id}?page=1&with=actionPlansUsersContacts;category;actionPlansFiles.children`
        )
        .then((response) => {
          setActionPlan(response.data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <Content
      title="Plano de Ação"
      loading={loading}
      titleRight={<ListButton to="/action-plans" />}
    >
      <Card variant="outlined">
        <CardContent
          sx={{
            display: 'flex',
            gap: 1,
            padding: [2, 3],
            bgcolor: 'common.white',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6} md={4}>
              <TextSubtitle>Situação:</TextSubtitle>
              <TextBody
                sx={{
                  color: getByIdActionPlansStatus(actionPlan?.situation)?.color,
                  display: 'inline-flex',
                }}
              >
                <Icon
                  sx={{
                    color: getByIdActionPlansStatus(actionPlan?.situation)
                      ?.color,
                  }}
                >
                  {getByIdActionPlansStatus(actionPlan?.situation)?.icon}
                </Icon>
                {getByIdActionPlansStatus(actionPlan?.situation)?.name}
              </TextBody>
            </Grid>
            <Grid item xs={6} md={4}>
              <TextSubtitle>Status:</TextSubtitle>
              <TextBody
                sx={{
                  color: getByIdActionPlansStatus(actionPlan?.status)?.color,
                  display: 'inline-flex',
                }}
              >
                <Icon
                  sx={{
                    color: getByIdActionPlansStatus(actionPlan?.status)?.color,
                  }}
                >
                  {getByIdActionPlansStatus(actionPlan?.status)?.icon}
                </Icon>
                {getByIdActionPlansStatus(actionPlan?.status)?.name}
              </TextBody>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextSubtitle>Categoria:</TextSubtitle>
              <TextBody>{actionPlan?.category?.name}</TextBody>
            </Grid>
            <Grid item xs={12}>
              <TextSubtitle>
                <TextSpan>O que</TextSpan> será feito:
              </TextSubtitle>
              <TextBody>{actionPlan?.what}</TextBody>
            </Grid>
            {actionPlan?.why && (
              <Grid item xs={12}>
                <TextSubtitle>
                  <TextSpan>Por que</TextSpan> será feito:
                </TextSubtitle>
                <TextBody>{actionPlan.why}</TextBody>
              </Grid>
            )}
            {actionPlan?.how_done && (
              <Grid item xs={12}>
                <TextSubtitle>
                  <TextSpan>Como</TextSpan> será feito:
                </TextSubtitle>
                <TextBody>{actionPlan.how_done}</TextBody>
              </Grid>
            )}
            {actionPlan?.where_done && (
              <Grid item xs={12}>
                <TextSubtitle>
                  <TextSpan>Onde</TextSpan> será feito:
                </TextSubtitle>
                <TextBody>{actionPlan.where_done}</TextBody>
              </Grid>
            )}

            {actionPlan?.situation === 2 && (
              <>
                {(actionPlan?.from_date || actionPlan?.when_finalized) && (
                  <>
                    {actionPlan?.from_date && (
                      <Grid item xs={6}>
                        <TextSubtitle>
                          <TextSpan>Quando</TextSpan> será iniciado:
                        </TextSubtitle>
                        <TextBody>
                          {format(new Date(actionPlan.from_date), 'dd/MM/yyyy')}
                        </TextBody>
                      </Grid>
                    )}
                    {actionPlan?.when_finalized && (
                      <Grid item xs={6}>
                        <TextSubtitle>
                          <TextSpan>Quando</TextSpan> será finalizado:
                        </TextSubtitle>
                        <TextBody>
                          {format(
                            new Date(actionPlan.when_finalized),
                            'dd/MM/yyyy'
                          )}
                        </TextBody>
                      </Grid>
                    )}
                  </>
                )}
                {actionPlan?.by_whom && (
                  <Grid item xs={12}>
                    <TextSubtitle>
                      <TextSpan>Por Quem</TextSpan> será feito:
                    </TextSubtitle>
                    <TextBody>{actionPlan.by_whom}</TextBody>
                  </Grid>
                )}
                {actionPlan?.how_much && (
                  <Grid item xs={12}>
                    <TextSubtitle>
                      <TextSpan>Quanto</TextSpan> custará:
                    </TextSubtitle>
                    <TextBody>{actionPlan.how_much}</TextBody>
                  </Grid>
                )}
              </>
            )}

            {actionPlan?.situation !== 2 && <></>}

            {actionPlan?.observation && (
              <Grid item xs={12}>
                <TextSubtitle>Resultado:</TextSubtitle>
                <TextBody>{actionPlan.observation}</TextBody>
              </Grid>
            )}
            {actionPlan?.action_plans_files &&
              actionPlan?.action_plans_files.length > 0 && (
                <Grid item xs={12}>
                  <TextSubtitle>Fotos:</TextSubtitle>
                  <Grid container spacing={2}>
                    {actionPlan.action_plans_files.map((actionPlanFile) => (
                      <ActionPlanFile
                        key={actionPlanFile.id}
                        actionPlanFile={actionPlanFile}
                      />
                    ))}
                  </Grid>
                </Grid>
              )}
          </Grid>
        </CardContent>
      </Card>
    </Content>
  );
}
