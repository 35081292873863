import { Logout } from '@mui/icons-material';
import PersonIcon from '@mui/icons-material/Person';
import {
  Avatar,
  Box,
  Button,
  ListItemIcon,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import { MouseEvent, useContext, useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { AuthContext } from '../../../contexts/AuthContext';

export function Profile(): JSX.Element {
  const { user, signOut } = useContext(AuthContext);
  const location = useLocation();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  const logout = () => {
    signOut();
    history.push('/login');
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClose();
  }, [location]);

  return (
    <>
      <Button
        id="basic-button"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          textAlign: 'right',
          gap: 1.25,
          alignItems: 'center',
          textTransform: 'none',
          minWidth: ['auto', '64px'],
          paddingRight: [0, 1],
        }}
      >
        <Box sx={{ display: ['none', 'none', 'block'] }}>
          <Typography
            variant="body1"
            component="div"
            color="text.primary"
            sx={{
              maxWidth: 180,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {user?.name}
          </Typography>
          <Typography
            variant="caption"
            component="div"
            color="text.secondary"
            sx={{
              maxWidth: 180,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {user?.account?.name}
          </Typography>
        </Box>
        <Avatar alt={user?.name} src={user?.avatar} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          variant: 'outlined',
          elevation: 0,
          sx: { bgcolor: 'common.white' },
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem component={Link} to="/profile" divider>
          <ListItemIcon>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          Meu Perfil
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Sair
        </MenuItem>
      </Menu>
    </>
  );
}
