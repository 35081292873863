import { Typography } from '@mui/material';

import { Title } from '../../components/Title';

export function Scroll(): JSX.Element {
  return (
    <>
      <Title title="Scroll" />
      <Typography variant="body1" component="div" sx={{ textAlign: 'justify' }}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum
        vestibulum, mi in scelerisque convallis, erat lorem rhoncus purus, in
        euismod quam diam semper est. Nulla facilisi. Suspendisse potenti. Proin
        commodo nunc turpis, id venenatis eros dictum eu. Morbi mattis, felis
        sit amet convallis porttitor, sem arcu tempus sapien, vel fringilla
        libero dolor ut felis. Sed non erat sem. Mauris augue ligula, lacinia eu
        malesuada at, hendrerit nec orci. In et justo vitae urna laoreet
        feugiat.
        <br />
        <br />
        Pellentesque viverra commodo sem vel rutrum. Proin vitae interdum
        lectus. Sed in aliquam mi. Vivamus malesuada egestas orci vel rhoncus.
        Donec faucibus accumsan consequat. Nulla efficitur lorem sit amet
        posuere maximus. Nam mauris turpis, commodo quis sem id, imperdiet
        faucibus lorem. Phasellus eu erat vitae elit volutpat mattis. Vivamus
        consectetur felis elit, non porta nisi fringilla nec.
        <br />
        <br />
        Quisque gravida fringilla ante, sed interdum mi ullamcorper nec. Donec
        tincidunt neque tempus magna blandit feugiat. Pellentesque ut tortor
        dignissim, ornare eros a, scelerisque ligula. Vivamus pellentesque elit
        augue, in pulvinar tellus malesuada at. Vestibulum pharetra interdum
        augue, pretium elementum erat dapibus id. Morbi nulla sapien, consequat
        consectetur enim id, rutrum iaculis nisl. Vivamus sed congue tellus.
        Integer ullamcorper leo ut aliquet porttitor. Maecenas non eleifend
        tortor. Integer tincidunt erat et congue vestibulum.
        <br />
        <br />
        Quisque non tincidunt metus. In tempor, neque eget facilisis tempor,
        ante leo scelerisque sem, id pretium lectus urna vitae elit. Cras odio
        orci, rutrum eu lectus vel, iaculis lacinia sem. Aliquam sem nisl,
        sollicitudin sit amet velit in, eleifend ultricies dui. In pharetra
        lectus eu pretium lacinia. Donec et libero sem. Maecenas sit amet
        rhoncus eros.
        <br />
        <br />
        Vestibulum et nisl vel arcu fringilla ornare. Phasellus euismod, lectus
        quis scelerisque mattis, turpis dolor posuere lectus, vel mollis justo
        nisi a sapien. Pellentesque lobortis vel lacus eu aliquam. Donec sit
        amet libero at dui consectetur venenatis. Aenean id eros nisi. Morbi
        congue, nibh sed pretium bibendum, elit odio luctus nunc, vitae feugiat
        nibh ipsum quis libero. Donec facilisis eget turpis eu pellentesque.
        Nunc interdum scelerisque pulvinar. Duis vehicula vehicula tristique.
        Phasellus ac lectus pretium, ornare dui id, finibus velit. Maecenas eu
        aliquet est. Maecenas gravida aliquam dolor non pulvinar.
        <br />
        <br />
        Nunc suscipit mollis eleifend. Fusce vitae laoreet nulla, in lobortis
        dolor. Pellentesque aliquam diam condimentum, dictum diam nec, mattis
        libero. Morbi quis imperdiet justo. Donec elementum tempor arcu nec
        sagittis. Aenean luctus tellus vel porta convallis. Integer nisi nulla,
        convallis id odio in, malesuada dapibus metus.
        <br />
        <br />
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed odio
        tellus, pretium ut massa sed, pulvinar semper sapien. Maecenas congue
        odio lacus, vel accumsan est placerat vitae. Maecenas risus arcu,
        facilisis placerat tortor eu, aliquet lacinia ligula. Nulla facilisi.
        Aliquam eget mauris sit amet metus commodo cursus. Sed pretium pretium
        orci. Cras sit amet justo ullamcorper, vestibulum justo vel, dapibus
        dolor. Duis sodales, tellus ut efficitur pharetra, diam elit convallis
        nibh, a auctor libero nisi vel purus. Nam porta, justo ut maximus
        molestie, urna mauris elementum erat, non consectetur arcu lectus in
        leo.
        <br />
        <br />
        Cras sit amet elit quis enim lacinia commodo. Sed tincidunt sagittis
        viverra. Vivamus posuere risus at nisl imperdiet hendrerit. Praesent
        odio felis, euismod efficitur quam in, semper elementum mi. Integer nec
        urna nunc. Fusce finibus sit amet dui a sodales. Aliquam maximus augue
        vitae egestas interdum. Quisque vitae sodales metus. Duis vitae finibus
        arcu, vel porta quam. Vestibulum ante ipsum primis in faucibus orci
        luctus et ultrices posuere cubilia curae; Class aptent taciti sociosqu
        ad litora torquent per conubia nostra, per inceptos himenaeos. Duis non
        faucibus velit, a gravida elit. Aenean tincidunt malesuada ultricies.
        <br />
        <br />
        Curabitur sit amet placerat nisl. Nulla molestie dui sed enim pharetra
        placerat. Nam eget purus et nunc consequat tempus. Sed sit amet odio nec
        nisi blandit imperdiet. Aliquam nec rhoncus ex, non molestie nisi. Morbi
        sem nisi, ultrices at tellus gravida, convallis tempor ligula. Praesent
        id aliquet risus. Duis et elit purus. Proin sit amet efficitur elit. In
        hac habitasse platea dictumst. Ut eros nulla, malesuada vitae auctor
        quis, tristique a turpis. Quisque id velit eget nunc bibendum eleifend
        id eget ipsum. Sed ultricies a magna sit amet finibus. Vivamus vulputate
        scelerisque sapien at porttitor. Vestibulum porttitor sollicitudin nisi,
        eget aliquam urna elementum a. Nulla lacus nunc, accumsan eu viverra
        vel, laoreet id est.
        <br />
        <br />
        Fusce quis neque mattis, convallis erat et, facilisis nisl. Vivamus
        ornare tristique est, quis laoreet est convallis quis. Praesent nec
        nulla ut eros porttitor sodales et nec justo. Etiam convallis lectus nec
        molestie venenatis. Proin sapien lectus, tempus et tempor a, consectetur
        a metus. Sed semper elementum ex, vitae sagittis nulla efficitur tempus.
        Mauris tincidunt ultrices faucibus. Praesent tincidunt facilisis
        tristique. Sed rhoncus ut sapien at aliquet. Donec suscipit, diam a
        sagittis interdum, quam dolor imperdiet sem, a tempor tortor felis ac
        lacus. Fusce eget lacinia quam. Nulla facilisi. Aenean nec massa
        gravida, vestibulum risus nec, tristique diam.
      </Typography>
    </>
  );
}
