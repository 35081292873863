import { Stack } from '@mui/material';
import MuiPagination from '@mui/material/Pagination';
import { ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '../../hooks/useQuery';
import { PaginationType } from '../../types/pagination';

export function Pagination({ totalPages }: PaginationType): JSX.Element {
  if (totalPages && totalPages > 1) {
    let link = '?p=XX';
    const history = useHistory();
    const location = useQuery();
    const page = Number(location.get('p') || '1');

    location.forEach((value, key) => {
      if (key !== 'p') {
        link += `&${key}=${value}`;
      }
    });

    const handleChange = (event: ChangeEvent<unknown>, value: number) => {
      history.push(link.replace('XX', String(value)));
    };

    return (
      <Stack sx={{ paddingTop: 4, alignItems: 'center' }}>
        <MuiPagination count={totalPages} page={page} onChange={handleChange} />
      </Stack>
    );
  }
  return <></>;
}
