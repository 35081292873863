import { Typography, TypographyProps } from '@mui/material';
import { ReactNode } from 'react';

export function TextSubtitle({
  children,
  ...rest
}: { children: ReactNode } & TypographyProps): JSX.Element {
  return (
    <Typography variant="subtitle1" gutterBottom {...rest}>
      {children}
    </Typography>
  );
}
