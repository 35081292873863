import React, { createContext, useContext, useEffect, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { useLocation } from 'react-router-dom';

import {
  SidebarDrawerContextType,
  SidebarDrawerProviderType,
} from '../../types/sidebar';

export const SidebarDrawerContext = createContext(
  {} as SidebarDrawerContextType
);

export const drawerWidthValue = 224;

export function SidebarDrawerProvider({
  children,
}: SidebarDrawerProviderType): JSX.Element {
  const location = useLocation();
  const [drawerWidth, setDrawerWidth] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(isDesktop);

  const handleDrawerWidth = () => {
    if (drawerOpen) {
      setDrawerWidth(drawerWidthValue);
    } else {
      setDrawerWidth(0);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    if (!isDesktop) {
      setDrawerOpen(false);
    }
  }, [location]);

  useEffect(() => {
    handleDrawerWidth();
  }, [drawerOpen]);

  return (
    <SidebarDrawerContext.Provider
      value={{ drawerWidth, drawerOpen, handleDrawerToggle }}
    >
      {children}
    </SidebarDrawerContext.Provider>
  );
}

export const useSidebarDrawer = (): SidebarDrawerContextType =>
  useContext(SidebarDrawerContext);
