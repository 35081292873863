import { createTheme } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          overflow: 'auto !important',
          backgroundColor: '#ffffff',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#5C2E91',
      light: '#9058d0',
    },
    success: {
      main: '#00A76D',
    },
    info: {
      main: '#46b7f4',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#777777',
      contrastText: '#ffffff',
    },
    background: {
      default: '#e6f3ff',
      paper: '#f0f8ff',
    },
    divider: '#d2eaff',
    action: {
      hover: '#f0f8ff',
    },
  },
  typography: {
    h1: {
      fontSize: '1.875rem',
      fontWeight: 500,
    },
    h2: {
      fontSize: '1.725rem',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.125rem',
    },
    h6: {
      fontSize: '1rem',
    },
    subtitle1: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  shadows: Array(25).fill('none') as Shadows,
});
