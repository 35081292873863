import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Avatar,
  Box,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Content } from '../../components/Content';
import { Button } from '../../components/Form/Button';
import { useQuery } from '../../hooks/useQuery';
import { api } from '../../services/Api';
import { ContactType } from '../../types/contact';
import { MetaType } from '../../types/pagination';

function stringAvatar(name: string, color?: string) {
  const split = name.split(' ');
  let initials = split[0][0];
  if (split.length > 1) {
    initials += split[split.length - 1][0];
  }
  return {
    sx: { bgcolor: color || 'primary.main' },
    children: initials,
  };
}

export function Contacts(): JSX.Element {
  const location = useQuery();
  const page = String(location.get('p') || '1');

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [modalContact, setModalContact] = useState<ContactType | null>(null);
  const [contacts, setContacts] = useState<ContactType[]>([]);
  const [meta, setMeta] = useState<MetaType>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<{ data: ContactType[]; meta: MetaType }>(
        `contacts?page=${page}&orderBy=name&sortedBy=asc&with=category`
      )
      .then((response) => {
        setMeta(response.data.meta);
        setContacts(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <Content
      title="Equipe"
      loading={loading}
      notFound={!contacts}
      totalPages={meta.last_page}
    >
      <Grid container spacing={2}>
        {contacts.map((contact) => (
          <Grid key={contact.id} item xs={12}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: [2, 3],
                  bgcolor: 'common.white',
                }}
              >
                <Box>
                  <Typography variant="body1" display="block" gutterBottom>
                    {contact.name}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {contact.category.name}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: 'space-between',
                  bgcolor: 'background.default',
                  padding: [2, 3],
                }}
              >
                <div />
                <Button
                  type="button"
                  onClick={() => {
                    setModalContact(contact);
                    handleOpen();
                  }}
                  size="small"
                  sx={{ width: 'auto', textTransform: 'none' }}
                  startIcon={<VisibilityIcon />}
                >
                  Visualizar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Dialog onClose={handleClose} open={open}>
        <DialogTitle
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '1rem',
            bgcolor: 'common.white',
          }}
        >
          {modalContact?.name && (
            <Avatar
              {...stringAvatar(modalContact.name, modalContact.category.color)}
            />
          )}
          {modalContact?.name}
        </DialogTitle>
        <DialogContent dividers sx={{ bgcolor: 'common.white' }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ borderColor: 'divider' }}>Cargo</TableCell>
                <TableCell sx={{ borderColor: 'divider' }}>
                  {modalContact?.category.name}
                </TableCell>
              </TableRow>
              {modalContact?.phone && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    Telefone
                  </TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.phone}
                  </TableCell>
                </TableRow>
              )}
              {modalContact?.email && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>E-mail</TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.email}
                  </TableCell>
                </TableRow>
              )}
              {modalContact?.birthday && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    Nascimento
                  </TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.birthday}
                  </TableCell>
                </TableRow>
              )}
              {modalContact?.sex && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>Sexo</TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.sex === 'F' ? 'Feminino' : 'Masculino'}
                  </TableCell>
                </TableRow>
              )}
              {modalContact?.cpf && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>CPF</TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.cpf}
                  </TableCell>
                </TableRow>
              )}
              {modalContact?.rg && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>RG</TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.rg}
                  </TableCell>
                </TableRow>
              )}
              {modalContact?.admission && (
                <TableRow>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    Data de admissão
                  </TableCell>
                  <TableCell sx={{ borderColor: 'divider' }}>
                    {modalContact.admission}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={handleClose}
            sx={{ width: 'auto', bgcolor: 'common.white' }}
            size="small"
          >
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </Content>
  );
}
