import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';

import { Content } from '../../components/Content';
import { Button } from '../../components/Form/Button';
import { useQuery } from '../../hooks/useQuery';
import { api } from '../../services/Api';
import { MetaType } from '../../types/pagination';
import { ReportsType } from '../../types/report';

export function Reports(): JSX.Element {
  const location = useQuery();
  const page = String(location.get('p') || '1');

  const [reports, setReports] = useState<ReportsType[]>([]);
  const [meta, setMeta] = useState<MetaType>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<{ data: ReportsType[]; meta: MetaType }>(
        `reports?page=${page}&orderBy=created_at&sortedBy=desc`
      )
      .then((response) => {
        setMeta(response.data.meta);
        setReports(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <Content
      title="Relatórios"
      loading={loading}
      notFound={!reports}
      totalPages={meta.last_page}
    >
      <Grid container spacing={2}>
        {reports.map((report) => (
          <Grid key={report.id} item xs={12}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 1,
                  padding: [2, 3],
                  bgcolor: 'common.white',
                }}
              >
                <Box>
                  <Typography variant="body1" display="block" gutterBottom>
                    {report.name}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {report.description}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: 'space-between',
                  bgcolor: 'background.default',
                  padding: [2, 3],
                }}
              >
                <Typography>{report.created_at}h</Typography>
                <Button
                  component="a"
                  href={report.file_name}
                  size="small"
                  sx={{ width: 'auto', textTransform: 'none' }}
                  startIcon={<VisibilityIcon />}
                  target="_blank"
                >
                  Visualizar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Content>
  );
}
