import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  Tab,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';

import { Content } from '../../components/Content';
import { Button } from '../../components/Form/Button';
import { api } from '../../services/Api';
import { DocumentType } from '../../types/document';

export function Documents(): JSX.Element {
  const [value, setValue] = useState('1');
  const [documents, setDocuments] = useState<DocumentType[]>([]);
  const [loading, setLoading] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    if (value === '1') {
      api
        .get<{ data: DocumentType[] }>(
          'documents?page=1&withFiles=1&sortedBy=asc'
        )
        .then((response) => {
          setDocuments(response.data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    } else {
      api
        .get<{ data: DocumentType[] }>(
          'documents/contacts?page=1&withFiles=1&sortedBy=asc'
        )
        .then((response) => {
          setDocuments(response.data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, [value]);

  function addDocument(document: DocumentType) {
    console.log(document);
  }

  function getColor(document: DocumentType): {
    label: string;
    color: string;
    icon: string;
  } {
    let label = '';
    if (document.expiry_date) {
      label = `Vencimento: ${document.expiry_date}`;
    } else if (
      document.document_id &&
      !document.pending &&
      !document.not_applicable
    ) {
      label = 'Vencimento: Indeterminado';
    }
    if (document.document_id && document.pending) {
      label = 'Pendente';
    }
    if (document.document_id && !document.not_applicable) {
      if (document.expired || document.pending) {
        return { color: 'error.main', icon: 'check', label };
      }
      return { color: 'success.main', icon: 'check', label };
    }
    if (!document.document_id && !document.not_applicable) {
      return { color: 'warning.main', icon: 'clear', label };
    }
    return {
      color: 'secondary.main',
      icon: 'remove_circle_outline',
      label,
    };
  }

  return (
    <Content title="Documentos" loading={loading} notFound={!documents}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Empresa" value="1" />
            <Tab label="Equipe" value="2" sx={{ display: 'none' }} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ paddingX: 0, paddingBottom: 0 }}>
          <Grid container spacing={2}>
            {documents.map((document) => (
              <Grid key={document.documents_item_id} item xs={12}>
                <Card variant="outlined">
                  <CardContent
                    sx={{
                      display: 'flex',
                      gap: 1,
                      padding: [2, 3],
                      bgcolor: 'common.white',
                    }}
                  >
                    <Icon sx={{ color: getColor(document).color }}>
                      {getColor(document).icon}
                    </Icon>
                    <Box>
                      <Typography variant="body1" display="block" gutterBottom>
                        {document.name}
                      </Typography>
                      <Typography variant="caption" display="block">
                        {document.comment}
                      </Typography>
                    </Box>
                  </CardContent>
                  <CardActions
                    sx={{
                      justifyContent: 'space-between',
                      bgcolor: 'background.default',
                      padding: [2, 3],
                    }}
                  >
                    <Typography
                      sx={{ marginTop: 1, color: getColor(document).color }}
                    >
                      {getColor(document).label}
                    </Typography>
                    <Box
                      sx={{
                        textAlign: 'right',
                        marginTop: -1,
                      }}
                    >
                      {document.documents_versions_files.map((documentFile) => (
                        <Button
                          component="a"
                          href={documentFile.file_name}
                          size="small"
                          color="success"
                          sx={{
                            width: 'auto',
                            marginTop: 1,
                            marginLeft: 1,
                            minWidth: 'auto',
                            '.MuiButton-startIcon': { margin: 0 },
                          }}
                          startIcon={<DownloadForOfflineIcon />}
                          title={
                            documentFile.file_caption
                              ? `Ver documento: ${documentFile.file_caption}`
                              : 'Ver documento.'
                          }
                          target="_blank"
                        />
                      ))}
                      <Button
                        onClick={() => addDocument(document)}
                        size="small"
                        sx={{
                          width: 'auto',
                          textTransform: 'none',
                          display: 'none',
                        }}
                        startIcon={<AddCircleOutlineIcon />}
                        title="Adiconar novo vencimento ou pendência."
                      >
                        Adicionar
                      </Button>
                    </Box>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TabPanel>
        <TabPanel value="2">Item Two</TabPanel>
      </TabContext>
    </Content>
  );
}
