import { Box } from '@mui/material';
import { BoxProps } from '@mui/material/Box/Box';
import { ReactNode } from 'react';

export function TextSpan({
  children,
  ...rest
}: { children: ReactNode } & BoxProps): JSX.Element {
  return (
    <Box component="span" sx={{ color: 'success.main' }} {...rest}>
      {children}
    </Box>
  );
}
