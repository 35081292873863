import Icon from '@mui/icons-material/Menu';
import { IconButton } from '@mui/material';

import { SidebarDrawerContext } from '../../../contexts/SidebarDrawerContext';

export function MenuIcon(): JSX.Element {
  return (
    <SidebarDrawerContext.Consumer>
      {({ handleDrawerToggle }) => (
        <IconButton
          size="large"
          edge="start"
          color="secondary"
          aria-label="menu"
          onClick={handleDrawerToggle}
          sx={{ mr: 2 }}
        >
          <Icon />
        </IconButton>
      )}
    </SidebarDrawerContext.Consumer>
  );
}
