import { Box, CircularProgress, Typography } from '@mui/material';

import { IContent } from '../../types/loading';
import { Pagination } from '../Pagination';
import { Title } from '../Title';

export function Content({
  loading,
  children,
  title,
  notFound,
  totalPages,
  titleRight,
}: IContent): JSX.Element {
  return (
    <>
      {title && (
        <Box
          sx={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Title title={title} />
          <Box>{titleRight}</Box>
        </Box>
      )}
      {!loading && !notFound && (
        <>
          {children}
          <Pagination totalPages={totalPages} />
        </>
      )}
      {!loading && notFound && (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
          <Typography
            variant="h6"
            component="h3"
            color="secondary"
            sx={{ fontWeight: 'normal' }}
          >
            Nenhum registro encontrado.
          </Typography>
        </Box>
      )}
      {loading && (
        <Box sx={{ padding: 4, textAlign: 'center' }}>
          <CircularProgress color="primary" />
        </Box>
      )}
    </>
  );
}
