import { yupResolver } from '@hookform/resolvers/yup';
import { Alert, Box, Grid } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import { Button } from '../../components/Form/Button';
import { Input } from '../../components/Form/Input';
import { AuthContext } from '../../contexts/AuthContext';
import { SignInRequestDataTypeAdmin } from '../../types/auth';

const signInFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('E-mail obrigatório')
    .email('E-mail inválido')
    .trim(
      'O e-mail não pode incluir caractere de espaço à esquerda e à direita'
    ),
  password: yup
    .string()
    .required('Senha obrigatória')
    .trim(
      'A senha não pode incluir caractere de espaço à esquerda e à direita'
    ),
  user_mail: yup
    .string()
    .required('E-mail do cliente obrigatório')
    .email('E-mail do cliente inválido')
    .trim(
      'O e-mail do cliente não pode incluir caractere de espaço à esquerda e à direita'
    ),
});

export function LoginAdmin(): JSX.Element {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(signInFormSchema),
  });
  const [errorMessage, setErrorMessage] = useState('');

  const { signInAdmin, signOut } = useContext(AuthContext);

  const handleSignIn: SubmitHandler<SignInRequestDataTypeAdmin> = async (
    data
  ) => {
    setErrorMessage('');
    try {
      await signInAdmin(data);
      history.push('/');
    } catch (err) {
      setErrorMessage((err as Error).message);
    }
  };

  useEffect(() => {
    signOut();
  }, []);

  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit(handleSignIn)}
      sx={{ maxWidth: 368 }}
    >
      <Grid container spacing={3}>
        {errorMessage && (
          <Grid item xs={12}>
            <Alert severity="error">{errorMessage}</Alert>
          </Grid>
        )}
        <Grid item xs={12}>
          <Input
            name="email"
            label="E-mail"
            error={errors.email}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="password"
            label="Senha"
            error={errors.password}
            control={control}
            type="password"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            name="user_mail"
            label="E-mail do Cliente"
            error={errors.user_mail}
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <Button isSubmitting={isSubmitting}>Entrar</Button>
        </Grid>
      </Grid>
    </Box>
  );
}
