import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';

import { IInput } from '../../types/form';

export const Input = ({
  name,
  control,
  label,
  error,
  type = 'text',
  ...rest
}: IInput): JSX.Element => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <TextField
          label={label}
          variant="outlined"
          type={type}
          required
          error={!!error}
          helperText={error?.message}
          {...field}
          {...rest}
          sx={{ width: '100%', bgcolor: 'common.white !important' }}
        />
      )}
    />
  );
};
