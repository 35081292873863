import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import { ScrollToTop } from './components/ScrollToTop';
import { AuthProvider } from './contexts/AuthContext';
import { Routes } from './routes';

export function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AuthProvider>
        <HelmetProvider>
          <ScrollToTop />
          <Routes />
        </HelmetProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
