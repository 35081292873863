import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  ClickAwayListener,
  Divider,
  IconButton,
  Link as LinkMui,
  ListItemText,
  MenuItem,
  MenuList,
  Popper,
} from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export function Notification(): JSX.Element {
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClickAway = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAnchorEl(null);
  }, [location]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : '';

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          borderRight: 1,
          borderColor: 'divider',
          paddingX: 1.25,
          marginX: 1.25,
        }}
      >
        <IconButton
          aria-label="cart"
          aria-describedby={id}
          // onClick={handleClick}
          sx={{
            '.MuiBadge-badge': {
              fontSize: '0.55rem',
              minWidth: '1rem',
              height: '1rem',
              padding: 0,
              right: '0.25rem',
            },
          }}
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <Popper
          id={id}
          open={open}
          anchorEl={anchorEl}
          placement="bottom"
          popperOptions={{
            modifiers: [
              {
                name: 'arrow',
                enabled: true,
                options: {
                  element: arrowRef,
                },
              },
            ],
          }}
        >
          <Box
            component="span"
            sx={{
              position: 'absolute',
              fontSize: 7,
              width: '3em',
              height: '3em',
              marginTop: '-0.9em',
              '&::before': {
                content: '""',
                margin: 'auto',
                display: 'block',
                width: 0,
                height: 0,
                borderStyle: 'solid',
                borderWidth: '0px 1em 1em',
                borderColor: 'transparent transparent #fafafa',
              },
            }}
            ref={setArrowRef}
          />
          <Card
            variant="outlined"
            sx={{
              maxWidth: '100%',
              width: [300, 350, 450],
              marginX: '10px',
            }}
          >
            <CardHeader
              action={
                <IconButton onClick={handleClick} aria-label="Fechar">
                  <CloseIcon />
                </IconButton>
              }
              title="Notificações"
              sx={{ bgcolor: 'background.default' }}
            />
            <Divider />
            <CardContent sx={{ p: 0, maxHeight: 335, overflowY: 'auto' }}>
              <MenuList dense sx={{ p: 0 }}>
                {['xxx1', 'ccccc2', 'xxxc3', 'cvcv4', 'xcvv5'].map((item) => (
                  <MenuItem key={item} divider sx={{ bgcolor: 'common.white' }}>
                    <LinkMui
                      component={Link}
                      to="/"
                      sx={{ textDecoration: 'none' }}
                    >
                      <ListItemText
                        primary="Although cards can support actions, UI
                        controls, and an overflow menu, use restraint and
                        remember that cards are entry points to more complex and
                        detailed information."
                        secondary="24/11/2021 14:55h"
                        sx={{
                          color: 'text.primary',
                          whiteSpace: 'normal',
                          '.MuiListItemText-primary': {
                            fontWeight: 500, // normal 400
                          },
                          '.MuiListItemText-secondary': {
                            fontSize: '0.75rem',
                          },
                        }}
                      />
                    </LinkMui>
                  </MenuItem>
                ))}
              </MenuList>
            </CardContent>
            <CardActions
              sx={{ justifyContent: 'center', bgcolor: 'background.default' }}
            >
              <Button
                variant="outlined"
                href="/"
                size="small"
                sx={{ bgcolor: 'common.white' }}
              >
                Ver todas
              </Button>
            </CardActions>
          </Card>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}
