import { Box, Card, Grid, Typography } from '@mui/material';

import { ActionPlanFilesType } from '../../types/actionPlans';

function SingleBox({
  actionPlanFile,
}: {
  actionPlanFile: ActionPlanFilesType;
}): JSX.Element {
  return (
    <Grid item xs={12} sm={6}>
      <Card
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: 2,
          height: '100%',
          width: '100%',
          bgcolor: 'common.white',
          textAlign: 'center',
        }}
      >
        <Box
          component="a"
          href={actionPlanFile?.file_name}
          target="_blank"
          sx={{ display: 'inherit' }}
        >
          <Box
            component="img"
            src={actionPlanFile?.file_name_medium}
            srcSet={actionPlanFile?.file_name_medium}
            alt={actionPlanFile?.file_caption}
            loading="lazy"
            sx={{ maxWidth: '100%', maxHeight: '350px' }}
          />
        </Box>
        {actionPlanFile?.file_caption && (
          <Typography
            variant="caption"
            display="block"
            color="secondary"
            mt={2}
          >
            {actionPlanFile.file_caption}
          </Typography>
        )}
      </Card>
    </Grid>
  );
}

export function ActionPlanFile({
  actionPlanFile,
}: {
  actionPlanFile: ActionPlanFilesType;
}): JSX.Element {
  return (
    <>
      {SingleBox({ actionPlanFile })}
      {actionPlanFile.children &&
        SingleBox({ actionPlanFile: actionPlanFile.children })}
    </>
  );
}
