import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '../Form/Button';

export type IButton = {
  children: ReactNode;
  isSubmitting: boolean;
};

export const ListButton = ({ ...rest }: IButton | any): JSX.Element => {
  return (
    <Button
      component={Link}
      size="small"
      color="success"
      sx={{
        textTransform: 'none',
        minWidth: 'auto',
        '.MuiButton-startIcon': {
          marginLeft: [0, '-2px'],
          marginRight: [0, '8px'],
        },
      }}
      startIcon={<FormatListBulletedIcon />}
      {...rest}
    >
      <Box sx={{ display: ['none', 'inline'] }}>Listar Todos</Box>
    </Button>
  );
};
