import { decode as base64_decode } from 'base-64';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { createContext, useState } from 'react';
import utf8 from 'utf8';

import { api } from '../../services/Api';
import {
  AuthContextType,
  IAuthProvider,
  SignInRequestDataType,
  SignInRequestDataTypeAdmin,
  UserAuthType,
} from '../../types/auth';
import { ErrorType } from '../../types/error';

export const AuthContext = createContext({} as AuthContextType);

export function AuthProvider({ children }: IAuthProvider): JSX.Element {
  const [user, setUser] = useState<UserAuthType | null>(null);

  function setUserDecode(data: UserAuthType) {
    data.name = base64_decode(data.name);
    data.email = base64_decode(data.email);
    data.avatar = base64_decode(data.avatar);
    data.account = {
      name: base64_decode(data.account?.name ? data.account?.name : ''),
      file_name: base64_decode(
        data.account?.file_name ? data.account?.file_name : ''
      ),
    };
    data.name = utf8.decode(data.name);
    data.account.name = utf8.decode(data.account.name);
    setUser(data);
  }

  async function signIn({
    email,
    password,
  }: SignInRequestDataType): Promise<UserAuthType> {
    try {
      const {
        data: { data },
      } = await api.post<{ data: UserAuthType }>('access_token', {
        email,
        password,
      });

      if (data.account?.settings) {
        data.account.settings = null;
      }

      localStorage.setItem('fcQualityUser', JSON.stringify(data));
      // setCookie(undefined, 'fcQualityUser', JSON.stringify(data), {
      //   maxAge: 60 * 60 * 24 * 7, // 1 week
      // });

      if (data.account?.file_name) {
        localStorage.setItem('fcLogo', base64_decode(data.account.file_name));
        // setCookie(undefined, 'fcLogo', base64_decode(data.account.file_name), {
        //   maxAge: 60 * 60 * 24 * 365, // 1 year
        //   path: '/',
        // });
      }

      setUserDecode(data);

      api.get('set_access').then();

      return data;
    } catch (err) {
      throw new Error((err as ErrorType).message);
    }
  }

  async function signInAdmin({
    email,
    password,
    user_mail,
  }: SignInRequestDataTypeAdmin): Promise<UserAuthType> {
    try {
      const {
        data: { data },
      } = await api.post<{ data: UserAuthType }>('access_token', {
        email,
        password,
        user_mail,
      });

      if (data.account?.settings) {
        data.account.settings = null;
      }

      localStorage.setItem('fcQualityUser', JSON.stringify(data));
      // setCookie(undefined, 'fcQualityUser', JSON.stringify(data), {
      //   maxAge: 60 * 60 * 24 * 7, // 1 week
      //   path: '/',
      // });

      if (data.account?.file_name) {
        localStorage.setItem('fcLogo', base64_decode(data.account.file_name));
        // setCookie(undefined, 'fcLogo', base64_decode(data.account.file_name), {
        //   maxAge: 60 * 60 * 24 * 365, // 1 year
        //   path: '/',
        // });
      }

      setUserDecode(data);

      return data;
    } catch (err) {
      throw new Error((err as ErrorType).message);
    }
  }

  function signOut(): void {
    setUser(null);
    localStorage.removeItem('fcQualityUser');
    // destroyCookie(undefined, 'fcQualityUser');
  }

  function verifyAuthentication(): boolean {
    if (!user) {
      const userCookies = localStorage.getItem('fcQualityUser');
      // const { fcQualityUser: userCookies } = parseCookies();
      if (userCookies) {
        const parse = JSON.parse(userCookies);
        setUserDecode(parse);
        api.get('set_access').then();
      }
    }
    return !!user;
  }

  verifyAuthentication();
  const isAuthenticated = !!user;

  return (
    <AuthContext.Provider
      value={{ user, isAuthenticated, signIn, signInAdmin, signOut }}
    >
      {children}
    </AuthContext.Provider>
  );
}
