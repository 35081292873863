import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Content } from '../../components/Content';
import { Button } from '../../components/Form/Button';
import { useQuery } from '../../hooks/useQuery';
import { getByIdActionPlansStatus } from '../../services/ActionPlansStatus';
import { api } from '../../services/Api';
import { ActionPlansType } from '../../types/actionPlans';
import { MetaType } from '../../types/pagination';

export function ActionPlans(): JSX.Element {
  const location = useQuery();
  const page = String(location.get('p') || '1');

  const [actionPlans, setActionPlans] = useState<ActionPlansType[]>([]);
  const [meta, setMeta] = useState<MetaType>({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<{ data: ActionPlansType[]; meta: MetaType }>(
        `action_plans?page=${page}&orderBy=when_finalized&sortedBy=asc&with=actionPlansUsersContacts;category;actionPlansProject;user`
      )
      .then((response) => {
        setMeta(response.data.meta);
        setActionPlans(response.data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, [page]);

  return (
    <Content
      title="Planos de Ação"
      loading={loading}
      notFound={!actionPlans}
      totalPages={meta.last_page}
    >
      <Grid container spacing={2}>
        {actionPlans.map((actionPlan) => (
          <Grid key={actionPlan.id} item xs={12}>
            <Card variant="outlined">
              <CardContent
                sx={{
                  display: 'flex',
                  gap: 1,
                  padding: [2, 3],
                  bgcolor: 'common.white',
                }}
              >
                <Icon
                  sx={{
                    color: getByIdActionPlansStatus(actionPlan.situation)
                      ?.color,
                  }}
                >
                  {getByIdActionPlansStatus(actionPlan.situation)?.icon}
                </Icon>
                <Box>
                  <Typography variant="body1" display="block" gutterBottom>
                    {actionPlan.what}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {actionPlan.by_whom &&
                      `Responsável: ${actionPlan.by_whom} - `}
                    Criado em:{' '}
                    {format(new Date(actionPlan.created_at), 'dd/MM/yyyy')}
                  </Typography>
                  {(actionPlan.from_date || actionPlan.when_finalized) && (
                    <Typography
                      variant="caption"
                      display="block"
                      sx={{ marginTop: '0.35em' }}
                    >
                      Prazo:{' '}
                      {actionPlan.from_date &&
                        format(
                          new Date(actionPlan.from_date),
                          'dd/MM/yyyy'
                        )}{' '}
                      {actionPlan.from_date && actionPlan.when_finalized
                        ? 'até'
                        : ''}{' '}
                      {actionPlan.when_finalized &&
                        format(
                          new Date(actionPlan.when_finalized),
                          'dd/MM/yyyy'
                        )}
                    </Typography>
                  )}
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  justifyContent: 'space-between',
                  bgcolor: 'background.default',
                  padding: [2, 3],
                }}
              >
                <Typography
                  sx={{
                    color: getByIdActionPlansStatus(actionPlan.situation)
                      ?.color,
                  }}
                >
                  {getByIdActionPlansStatus(actionPlan.situation)?.name}
                </Typography>
                <Button
                  component={Link}
                  to={`/action-plans/${actionPlan.id}`}
                  size="small"
                  sx={{ width: 'auto', textTransform: 'none' }}
                  startIcon={<VisibilityIcon />}
                >
                  Visualizar
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Content>
  );
}
