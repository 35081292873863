import { LoadingButton } from '@mui/lab';
import { ReactNode } from 'react';

export type IButton = {
  children: ReactNode;
  isSubmitting: boolean;
};

export const Button = ({
  children,
  isSubmitting,
  ...rest
}: IButton | any): JSX.Element => {
  return (
    <LoadingButton
      variant="contained"
      size="large"
      loading={isSubmitting}
      sx={{ width: '100%' }}
      color="primary"
      type="submit"
      {...rest}
    >
      {children}
    </LoadingButton>
  );
};
