import { Box, Button, Typography } from '@mui/material';

import { Title } from '../../components/Title';

export function Elements(): JSX.Element {
  return (
    <>
      <Title title="Teste de Elementos" />
      <Box
        sx={{
          display: 'inline-flex',
          flexDirection: 'column',
          gap: 3,
          width: 'auto',
        }}
      >
        <Typography
          color="success.main"
          variant="body1"
          component="div"
          gutterBottom
        >
          Jorge Fernando da Silva Jardim
        </Typography>
        <Typography
          color="disabled"
          variant="caption"
          component="div"
          gutterBottom
        >
          Rejane Maria da Silva Jardim
        </Typography>
        <Button variant="contained" color="primary">
          Hello World
        </Button>
        <Button variant="contained" color="success">
          Hello World
        </Button>
        <Button variant="contained" color="error" sx={{ my: 1 }}>
          Hello World
        </Button>
        <Button variant="contained" color="warning">
          Hello World
        </Button>
        <Button variant="contained" color="info">
          Hello World
        </Button>
        <Button variant="contained" color="secondary">
          Hello World
        </Button>
        <Button variant="contained" color="success" disabled>
          Hello World
        </Button>
        <Button variant="contained" color="success" href="https://uol.com.br">
          Link UOL
        </Button>
        <Button variant="contained" color="primary">
          Hello World
        </Button>
        <Button variant="contained" color="success">
          Hello World
        </Button>
        <Button variant="contained" color="error" sx={{ my: 1 }}>
          Hello World
        </Button>
        <Button variant="contained" color="warning">
          Hello World
        </Button>
        <Button variant="contained" color="info">
          Hello World
        </Button>
        <Button variant="contained" color="secondary">
          Hello World
        </Button>
        <Button variant="contained" color="success" disabled>
          Hello World
        </Button>
        <Button variant="contained" color="success" href="https://uol.com.br">
          Link UOL
        </Button>
      </Box>
    </>
  );
}
